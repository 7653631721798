<template>
  <div>
    <div class="pp-entity__view">
      <div 
        ref="entityViewTabs" 
        class="pp-entity__view-tabs"
      >
        <div 
          class="pp-entity__view-tabs-item"
          :class="{ 'pp-entity__view-tabs-item--active': activeTab === 'overview' }"
          @click="handleActiveTab('overview')"
        >
          Overview
        </div>
        <div 
          v-if="tabsHasRegulatoryCompliance"
          class="pp-entity__view-tabs-item"
          :class="{ 'pp-entity__view-tabs-item--active': activeTab === 'regulatory-compliance' }"
          @click="handleActiveTab('regulatory-compliance')"  
        >
          Regulatory Compliance
        </div>
        <div 
          v-if="tabsHasBanks"
          class="pp-entity__view-tabs-item"
          :class="{ 'pp-entity__view-tabs-item--active': activeTab === 'banks' }"
          @click="handleActiveTab('banks')"  
        >
          Banks
        </div>
        <div 
          v-if="tabsHasPaymentSystems"
          class="pp-entity__view-tabs-item"
          :class="{ 'pp-entity__view-tabs-item--active': activeTab === 'payment-systems' }"
          @click="handleActiveTab('payment-systems')"  
        >
          Payment Service
        </div>
        <div 
          v-if="tabsHasContacts"
          class="pp-entity__view-tabs-item"
          :class="{ 'pp-entity__view-tabs-item--active': activeTab === 'contacts' }"
          @click="handleActiveTab('contacts')"  
        >
          Contacts
        </div>
      </div>
    </div>
    <div class="entity-view-wrapper mobile-background">
      <div
        v-show="loading"
        class="flex align-center justify-center full-height"
      >
        <gl-loader />
      </div>
      <div v-show="!loading">
        <div
          v-show="!Object.keys(formData).length"
          class="pp-entity__table-wrap"
        >
          <div class="entity-empty__wrapper">
            No data...
          </div>
        </div>

        <div v-show="Object.keys(formData).length">
          <div
            v-show="activeTab === 'overview'"
            class="pp-entity__table-wrap"
          >
            <EntityOverviewView
              :form-data="formData"
              :loading-screenshots="loadingScreenshots"
              @setImageModalData="
                (data, i) => $emit('setImageModalData', data, i)
              "
              @showViewImageModal="$emit('showViewImageModal')"
            />
          </div>
          <div v-show="activeTab === 'regulatory-compliance'">
            <div
              v-if="
                formData.data &&
                  formData.data.regulatoryCompliance &&
                  formData.data.regulatoryCompliance.length
              "
              class="pp-entity__table-wrap"
            >
              <EntityRegulatoryComplianceTable
                class="regulatory-compliance-table"
                :table-data="formData.data.regulatoryCompliance"
              />
            </div>
            <div
              v-else
              class="pp-entity__table-wrap"
            >
              You currently have no regulation information.
            </div>
          </div>
          <div v-show="activeTab === 'banks'">
            <div
              v-if="
                formData.data && formData.data.banks && formData.data.banks.length
              "
              class="pp-entity__table-wrap"
            >
              <EntityBanksTable 
                class="banks-table" 
                :table-data="formData.data.banks" 
              />
            </div>
            <div
              v-else
              class="pp-entity__table-wrap"
            >
              You currently have no banks.
            </div>
          </div>
          <div v-show="activeTab === 'payment-systems'">
            <div
              v-if="
                formData.data &&
                  formData.data.paymentSystems &&
                  formData.data.paymentSystems.length
              "
              class="pp-entity__table-wrap"
            >
              <EntityPaymentSystemsTable
                class="payments-systems-table"
                :table-data="formData.data.paymentSystems"
              />
            </div>
            <div
              v-else
              class="pp-entity__table-wrap"
            >
              You currently have no payment service.
            </div>
          </div>
          <div
            v-show="activeTab === 'contacts'"
            class="pp-entity__table-wrap"
          >
            <div class="entity-view-overview-wrapper fullwidth contacts-people-departments-wrap">
              <div class="fs-16 bold-600 pa-4 pb-3">
                People
              </div>
              <div
                v-if="
                  formData.data &&
                    formData.data.contacts &&
                    formData.data.contacts.length
                "
              >
                <EntityContactsTable 
                  class="contacts-people-table" 
                  :table-data="formData.data.contacts" 
                />
              </div>
              <div
                v-else
                class="entity-view-content-contract-department-wrapper flex align-center justify-center bold fs-14"
              >
                You currently have no contacts.
              </div>
              <div class="fs-16 bold-600 pa-4 pb-3">
                Departments
              </div>
              <div
                v-if="
                  formData.data &&
                    formData.data.contactsDepartments &&
                    formData.data.contactsDepartments.length
                "
              >
                <EntityContactsDepartmentsTable
                  class="contacts-departments-table" 
                  :table-data="formData.data.contactsDepartments"
                />
              </div>
              <div
                v-else
                class="entity-view-content-contract-department-wrapper flex align-center justify-center bold fs-14"
              >
                You currently have no Departments.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Components
import GlLoader from '@/components/gl-loader'
import EntityOverviewView from '@/pages/entity/components/EntityOverviewView.vue'
import EntityContactsTable from '@/pages/entity/components/EntityContactsTable.vue'
import EntityBanksTable from '@/pages/entity/components/EntityBanksTable.vue'
import EntityRegulatoryComplianceTable from '@/pages/entity/components/EntityRegulatoryComplianceTable.vue'
import EntityPaymentSystemsTable from '@/pages/entity/components/EntityPaymentSystemsTable.vue'
import EntityContactsDepartmentsTable from '@/pages/entity/components/EntityContactsDepartmentsTable.vue'
// Utils
import { capitalizeFirstLetter } from '@/utils/text-formatter'
import { isValidURL } from '@/utils/text-formatter'
// Vuex
import { mapActions, mapMutations } from 'vuex'

export default {
  components: {
    GlLoader,
    EntityContactsTable,
    EntityBanksTable,
    EntityRegulatoryComplianceTable,
    EntityOverviewView,
    EntityPaymentSystemsTable,
    EntityContactsDepartmentsTable,
  },
  data() {
    return {
      formData: {},
      loading: false,
      loadingScreenshots: false,
      activeTab: 'overview',
    }
  },
  computed: {
    tabsHasRegulatoryCompliance() {
      return this.formData?.data?.regulatoryCompliance?.length
    },
    tabsHasBanks() {
      return this.formData?.data?.banks?.length
    },
    tabsHasPaymentSystems() {
      return this.formData?.data?.paymentSystems?.length
    },
    tabsHasContacts() {
      return this.formData?.data?.contacts?.length || this.formData?.data?.contactsDepartments?.length
    },
  },
  watch: {
    $route: {
      handler() {
        this.activeTab = 'overview'
        this.loadEntity()
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('entity', ['getEntityById']),
    ...mapMutations('entity', ['SET_ACTIVE_ENTITY']),
    capitalizeFirstLetter,
    isValidURL,
    async loadEntity() {
      this.loading = true
      await this.getEntityById({
        ids: this.$route.params.entityId,
        screenshots: 0,
      })
        .then(({ items }) => {
          if (items && items.length) {
            this.SET_ACTIVE_ENTITY({
              id: items[0].id,
              name: items[0].name.value,
            })
            this.formData = items[0]
            this.loadEntityScreenshots()
          } else {
            this.$toasted.global.error({ message: 'No data' })
          }
        })
        .catch(e => {
          this.$toasted.global.error({
            message: e?.response?.data?.data?.message || e,
          })
        })
        .finally(() => {
          this.loading = false
        })

      this.loading = false
    },
    async loadEntityScreenshots() {
      this.loadingScreenshots = true
      await this.getEntityById({
        ids: this.$route.params.entityId,
        screenshots: 1,
      })
        .then(({ items }) => {
          if (items && items.length) {
            if (this.formData.data) {
              this.$set(
                this.formData.data,
                'screenshots',
                items[0].data?.screenshots,
              )
            }
          }
        })
        .catch(e => {
          this.$toasted.global.error({
            message: e?.response?.data?.data?.message || e,
          })
        })
        .finally(() => {
          this.loadingScreenshots = false
        })
      this.loadingScreenshots = false
    },
    handleActiveTab(name) {
      this.activeTab = name
      this.$nextTick(() => {
        this.$refs.entityViewTabs.scrollLeft =
          document.querySelector('.tab-active').offsetLeft - 495
      })
    },
  },
}
</script>

<style>
.pp-entity__view-tabs {
  display: flex;
  height: 40px;
  position: relative;
  overflow: auto;
}
.pp-entity__view-tabs::-webkit-scrollbar {
  height: 6px;
}
.pp-entity__view-tabs:after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--cotton-grey-f-9);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.pp-entity__view-tabs-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  font-size: 14px;
  color: var(--dark-grey-6-e);
  text-align: center;
  white-space: nowrap;
  position: relative;
  z-index: 2;
  cursor: pointer;
  transition: color 0.3s ease;
}
.pp-entity__view-tabs-item:after {
  content: "";
  width: 0%;
  height: 2px;
  background-color: var(--soft-blue);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  transition: width 0.3s ease;
}
.pp-entity__view-tabs-item--active {
  font-weight: 600;
  color: var(--space-cadet);
}
.pp-entity__view-tabs-item--active:after {
  width: 100%;
}


/* Base table styles */
.pp-entity__table-wrap .o-table__wrapper {
  min-height: 0;
}
.pp-entity__table-wrap .o-table__wrapper::-webkit-scrollbar {
  height: 8px;
}
.pp-entity__table-wrap .o-table__pagination {
  display: none !important;
}
.pp-entity__table-wrap .o-table__th {
  padding: 12px !important;
  border-bottom: 1px solid var(--cotton-grey-f-5);
  font-weight: 600 !important;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  white-space: nowrap;
}
.pp-entity__table-wrap .o-table__th:first-child {
  padding-left: 24px !important;
}
.pp-entity__table-wrap .o-table__td {
  min-height: 56px;
  border-bottom: 1px solid var(--cotton-grey-f-5);
  padding: 0 12px !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.pp-entity__table-wrap .o-table__td:first-child {
  padding-left: 24px !important;
}
.pp-entity__table-inner-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  min-width: 200px;
  min-height: 56px;
  padding: 8px 0;
}
.pp-entity__table-inner-item--soc {
  flex-wrap: nowrap;
  gap: 8px 16px;
}
.pp-entity__table-inner-item-country-wrap {
  display: flex;
  align-items: center;
}
.pp-entity__table-inner-item-country-wrap img {
  margin-right: 8px;
}
.pp-entity__table-inner-item--middle {
  min-width: 140px;
}
.pp-entity__table-inner-item--small {
  min-width: 90px;
}
.pp-entity__table-inner-item-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.pp-entity__table-inner-item-soc-icon a,
.pp-entity__table-inner-item-soc-icon img,
.pp-entity__table-inner-item-soc-icon svg {
  display: block;
}
.small-actions {
  gap: 8px;
}
.small-actions .gl-menu-item {
  padding: 0;
}

/* regulatory-compliance-table */
.regulatory-compliance-table .o-table__td:first-child {
  max-width: 190px;
  min-width: 190px;
  white-space: nowrap;
}
.regulatory-compliance-table .o-table__td:first-child .pp-entity__table-inner-item {
  flex-wrap: nowrap;
  min-width: 0;
}

/* banks-table */
.banks-table .o-table__td:first-child {
  max-width: 190px;
  min-width: 190px;
  white-space: nowrap;
}

/* contacts-people-table */
.contacts-people-table .o-table__td:last-child {
  max-width: 33%;
  min-width: 33%;
  width: 33%;
}

/* contacts-departments-table */
.contacts-departments-table .o-table__td:last-child {
  max-width: 33%;
  min-width: 33%;
  width: 33%;
}

@media (max-width: 1023px) {
  .pp-entity__view-tabs-item {
    padding: 0 16px;
  }
}
@media (max-width: 767px) {
  .pp-entity__view {
    margin-bottom: 10px;
  }
  .pp-entity__view-tabs {
    background-color: var(--white);
  }
  .pp-entity__view-tabs:after {
    display: none;
  }
  .mobile-background {
    width: calc(100% - 20px);
    margin: 0 auto;
    background-color: var(--white);
    border-radius: 12px;
  }

  .pp-entity__table-inner-item--soc {
    min-width: 0;
  }

  /* contacts-people-table */
  .contacts-people-table .o-table__td:last-child {
    max-width: auto;
    min-width: 0;
    width: auto;
  }

  /* contacts-departments-table */
  .contacts-departments-table .o-table__td:last-child {
    max-width: auto;
    min-width: 0;
    width: auto;
  }
}
</style>
