var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"entity-view-overview-wrapper flex m-display-block fullwidth"},[_c('div',{staticClass:"col-6 pa-4"},[_c('div',{staticClass:"fs-16 bold-600 mb-4 entity-view__big-header"},[_vm._v(" Summary ")]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Entity Name ")]),(_vm.formData.name)?_c('div',{staticClass:"entity-view-value"},[_vm._v(" "+_vm._s(_vm.formData.name)+" ")]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Legal Name ")]),(
          _vm.formData.data &&
            _vm.formData.data.legalName
        )?_c('div',{staticClass:"entity-view-value"},[_vm._v(" "+_vm._s(_vm.formData.data.legalName)+" ")]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Parent company / ownership ")]),(
          _vm.formData.data &&
            _vm.formData.data.parentCompany
        )?_c('div',{staticClass:"entity-view-value"},[_vm._v(" "+_vm._s(_vm.formData.data.parentCompany)+" ")]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Entity website ")]),(
          _vm.formData.data &&
            _vm.formData.data.website &&
            _vm.formData.data.website.length
        )?_c('div',{staticClass:"entity-view-value"},_vm._l((_vm.formData.data.website),function(item,i){return _c('div',{key:i,staticClass:"mr-2 display-inline"},[_c('div',{staticClass:"display-inline"},[(_vm.isValidURL(item))?_c('div',{staticClass:"display-inline word-break"},[_c('a',{staticClass:"accent-text-link",attrs:{"href":item,"target":"_blank"}},[_vm._v(_vm._s(item))])]):_c('div',{staticClass:"display-inline word-break"},[_vm._v(" "+_vm._s(item || '–')+" ")]),(i !== _vm.formData.data.website.length - 1)?_c('span',[_vm._v(" , ")]):_vm._e()])])}),0):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Domiciled Country ")]),(_vm.formData.data && _vm.formData.data.domicileCountry)?_c('div',{staticClass:"entity-view-value"},[_c('div',{staticClass:"flex align-center"},[(_vm.getCountryByCode(_vm.formData.data.domicileCountry)?.icon)?_c('GlImageViewer',{staticClass:"mr-1 flag-icon-small",attrs:{"src":_vm.getCountryByCode(_vm.formData.data.domicileCountry)?.icon}}):_vm._e(),_vm._v(" "+_vm._s(_vm.getCountryByCode(_vm.formData.data.domicileCountry)?.name)+" ")],1)]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Status ")]),(_vm.formData.data && _vm.formData.data.status)?_c('div',{staticClass:"entity-view-value bold-600"},[_vm._v(" "+_vm._s(_vm.formData.data.status)+" ")]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Type ")]),(
          _vm.formData &&
            _vm.formData.type &&
            _vm.formData.type
        )?_c('div',{staticClass:"entity-view-value"},[_c('GlTag',{staticClass:"mr-1 mb-1",attrs:{"score":_vm.formData.type.score,"tag":_vm.formData.type.name}})],1):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block max-w-400 overflow-auto"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Associated tags ")]),(
          _vm.formData &&
            _vm.formData.tags &&
            _vm.formData.tags.length
        )?_c('div',{staticClass:"entity-view-value"},_vm._l((_vm.formData.tags),function(tag,index){return _c('GlTag',{key:index,staticClass:"mr-1 mb-1",attrs:{"score":tag.score,"tag":tag.name}})}),1):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Description ")]),(
          _vm.formData.data &&
            _vm.formData.data.description
        )?_c('div',{staticStyle:{"white-space":"pre-wrap"}},[_c('GlEditor',{staticClass:"view-entity-quill-editor",attrs:{"disabled":"","value":_vm.formData.data.description}})],1):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Screenshots ")]),(_vm.loadingScreenshots)?_c('VueSkeletonLoader',{attrs:{"animation":"wave","color":'#bec3d8',"height":120,"radius":'2',"type":"rect"}}):_c('div',[(
            _vm.formData.data &&
              _vm.formData.data.screenshots &&
              _vm.formData.data.screenshots.length
          )?_c('div',{staticClass:"entity-screensot-wrapper"},_vm._l((_vm.formData.data.screenshots),function(item,i){return _c('div',{key:i,staticClass:"entity-screensot",on:{"click":function($event){_vm.$emit('showViewImageModal')
              _vm.$emit('setImageModalData', _vm.formData.data.screenshots, i)}}},[(item.image && item.image.includes('data:application/pdf;'))?_c('div',[_c('embed',{staticClass:"pt-4",attrs:{"src":item.image,"type":"application/pdf"}}),_c('div',{staticClass:"entity-screensot-full-view-icon link-text"},[_vm._v(" Full view ")])]):_c('GlImageViewer',{attrs:{"src":item.image}})],1)}),0):_c('div',[_vm._v(" – ")])])],1)]),_c('div',{staticClass:"col-6 pa-4"},[_c('div',{staticClass:"fs-16 bold-600 mb-4 entity-view__big-header"},[_vm._v(" Entity Details ")]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" KYC ")]),(
          _vm.formData.data && _vm.formData.data.KYCStatus
        )?_c('div',{staticClass:"entity-view-value entity-view-value--17 bold-600"},[_vm._v(" "+_vm._s(_vm.formData.data.KYCStatus)+" ")]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Provided Services ")]),(_vm.tempProvServicesFilter.length)?_c('div',{staticClass:"entity-view-value entity-view-value--flag flex flex-wrap"},[_vm._l((_vm.tempProvServicesFilter),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index <= _vm.showItemsLenght || _vm.showFullRegions),expression:"index <= showItemsLenght || showFullRegions"}],key:item.id,staticClass:"flex align-center mr-2"},[_c('div',[_vm._v(" "+_vm._s(item.name)+" ")]),(
              index !== _vm.tempProvServicesFilter.length - 1
            )?_c('span',[_vm._v(",")]):_vm._e()])}),(
            _vm.tempProvServicesFilter.length - 1 >
              _vm.showItemsLenght && !_vm.showFullRegions
          )?_c('div',{staticClass:"accent-text-link pointer",on:{"click":function($event){_vm.showFullRegions = true}}},[_vm._v(" Show more... ")]):_vm._e(),(
            _vm.tempProvServicesFilter.length - 1 >
              _vm.showItemsLenght && _vm.showFullRegions
          )?_c('div',{staticClass:"accent-text-link pointer",on:{"click":function($event){_vm.showFullRegions = false}}},[_vm._v(" Show less ")]):_vm._e()],2):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Primary operational regions ")]),(
          _vm.formData.data &&
            _vm.formData.data.primaryOperationalRegions &&
            _vm.formData.data.primaryOperationalRegions.length
        )?_c('div',{staticClass:"entity-view-value entity-view-value--flag flex flex-wrap"},[_vm._l((_vm.formData.data.primaryOperationalRegions),function(item,ind){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(ind <= _vm.showItemsLenght || _vm.showFullRegions),expression:"ind <= showItemsLenght || showFullRegions"}],key:item,staticClass:"flex align-center mr-2"},[(_vm.getCountryByCode(item)?.icon || '')?_c('GlImageViewer',{staticClass:"mr-1 flag-icon-small",attrs:{"src":_vm.getCountryByCode(item)?.icon || ''}}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.getCountryByCode(item)?.name || item)+" ")]),(
              ind !== _vm.formData.data.primaryOperationalRegions.length - 1
            )?_c('span',[_vm._v(",")]):_vm._e()],1)}),(
            _vm.formData.data.primaryOperationalRegions.length - 1 >
              _vm.showItemsLenght && !_vm.showFullRegions
          )?_c('div',{staticClass:"accent-text-link pointer",on:{"click":function($event){_vm.showFullRegions = true}}},[_vm._v(" Show more... ")]):_vm._e(),(
            _vm.formData.data.primaryOperationalRegions.length - 1 >
              _vm.showItemsLenght && _vm.showFullRegions
          )?_c('div',{staticClass:"accent-text-link pointer",on:{"click":function($event){_vm.showFullRegions = false}}},[_vm._v(" Show less ")]):_vm._e()],2):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Restricted Jurisdictions ")]),(
          _vm.formData.data &&
            _vm.formData.data.restrictedJurisdictions &&
            _vm.formData.data.restrictedJurisdictions.length
        )?_c('div',{staticClass:"entity-view-value entity-view-value--flag flex flex-wrap"},[_vm._l((_vm.formData.data.restrictedJurisdictions),function(item,ind){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(ind <= _vm.showItemsLenght || _vm.showFullJurisdictions),expression:"ind <= showItemsLenght || showFullJurisdictions"}],key:item,staticClass:"flex align-center mr-2"},[(_vm.getCountryByCode(item)?.icon || '')?_c('GlImageViewer',{staticClass:"mr-1 flag-icon-small",attrs:{"src":_vm.getCountryByCode(item)?.icon || ''}}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.getCountryByCode(item)?.name || item)+" ")]),(
              ind !== _vm.formData.data.restrictedJurisdictions.length - 1
            )?_c('span',[_vm._v(",")]):_vm._e()],1)}),(
            _vm.formData.data.restrictedJurisdictions.length - 1 >
              _vm.showItemsLenght && !_vm.showFullJurisdictions
          )?_c('div',{staticClass:"accent-text-link pointer",on:{"click":function($event){_vm.showFullJurisdictions = true}}},[_vm._v(" Show more... ")]):_vm._e(),(
            _vm.formData.data.restrictedJurisdictions.length - 1 >
              _vm.showItemsLenght && _vm.showFullJurisdictions
          )?_c('div',{staticClass:"accent-text-link pointer",on:{"click":function($event){_vm.showFullJurisdictions = false}}},[_vm._v(" Show less ")]):_vm._e()],2):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Fiat currency trading ")]),(
          _vm.formData.data &&
            _vm.formData.data.isFiatCurrencyTrading
        )?_c('div',{staticClass:"entity-view-value"},[_vm._v(" "+_vm._s(_vm.formData.data.isFiatCurrencyTrading ? 'Yes' : 'No')+" ")]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Office addresses ")]),(
          _vm.formData.data &&
            _vm.formData.data.officeAddress
        )?_c('div',{staticClass:"entity-view-value"},[_vm._v(" "+_vm._s(_vm.formData.data.officeAddress)+" ")]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Languages ")]),(
          _vm.formData.data &&
            _vm.formData.data.languages &&
            _vm.formData.data.languages.length
        )?_c('div',[(_vm.formData.data.languages.length)?_c('div',{staticClass:"entity-view-value flex flex-wrap"},[_vm._l((_vm.formData.data.languages),function(item,ind){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(ind <= _vm.showItemsLenght || _vm.showFullLanguage),expression:"ind <= showItemsLenght || showFullLanguage"}],key:item,staticClass:"flex align-baseline mr-2"},[_vm._v(" "+_vm._s(item)+" "),(ind !== _vm.formData.data.languages.length - 1)?_c('span',[_vm._v(",")]):_vm._e()])}),(
              _vm.formData.data.languages.length - 1 > _vm.showItemsLenght &&
                !_vm.showFullLanguage
            )?_c('div',{staticClass:"accent-text-link pointer",on:{"click":function($event){_vm.showFullLanguage = true}}},[_vm._v(" Show more... ")]):_vm._e(),(
              _vm.formData.data.languages.length - 1 > _vm.showItemsLenght &&
                _vm.showFullLanguage
            )?_c('div',{staticClass:"accent-text-link pointer",on:{"click":function($event){_vm.showFullLanguage = false}}},[_vm._v(" Show less ")]):_vm._e()],2):_vm._e()]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Privacy coins supported flag ")]),(
          _vm.formData.data &&
            _vm.formData.data.isPrivacyCoinsSupported
        )?_c('div',{staticClass:"entity-view-value"},[_vm._v(" "+_vm._s(_vm.formData.data.isPrivacyCoinsSupported ? 'Yes' : 'No')+" ")]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Social Network link ")]),(
          _vm.formData.data &&
            _vm.formData.data.socialNetworkLinks &&
            _vm.formData.data.socialNetworkLinks.length
        )?_c('div',{staticClass:"entity-view-value entity-view-value--social flex flex-wrap"},_vm._l((_vm.formData.data.socialNetworkLinks),function(item,i){return _c('div',{key:i,staticClass:"flex align-baseline"},[(item && _vm.isEmail(item))?_c('div',{staticClass:"pointer",on:{"click":function($event){return _vm.goMailto(item)}}},[(_vm.getValueByKey(_vm.socialIconsList, 'Mail', 'key'))?_c('GlImageViewer',{staticClass:"icon-small",attrs:{"src":_vm.getValueByKey(_vm.socialIconsList, 'Mail', 'key')}}):_vm._e()],1):(item && _vm.isValidURL(item))?_c('a',{attrs:{"href":item,"target":"_blank"}},[(_vm.getValueByKey(_vm.socialIconsList, _vm.getSocialName(item), 'key'))?_c('GlImageViewer',{staticClass:"icon-small",attrs:{"src":_vm.getValueByKey(_vm.socialIconsList, _vm.getSocialName(item), 'key')}}):_c('gl-icon',{staticClass:"mt-1",attrs:{"height":24,"link":"","name":"open","width":26}})],1):_c('div',[_vm._v(" "+_vm._s(item || '–')+" ")])])}),0):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }