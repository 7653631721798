<template>
  <div class="entity-view-overview-wrapper flex m-display-block fullwidth">
    <div class="col-6 pa-4">
      <div class="fs-16 bold-600 mb-4 entity-view__big-header">
        Summary
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Entity Name
        </div>
        <div
          v-if="formData.name"
          class="entity-view-value"
        >
          {{ formData.name }}
        </div>
        <div
          v-else
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Legal Name
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.legalName
          "
          class="entity-view-value"
        >
          {{ formData.data.legalName }}
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Parent company / ownership
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.parentCompany
          "
          class="entity-view-value"
        >
          {{ formData.data.parentCompany }}
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Entity website
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.website &&
              formData.data.website.length
          "
          class="entity-view-value"
        >
          <div
            v-for="(item, i) in formData.data.website"
            :key="i"
            class="mr-2 display-inline"
          >
            <div class="display-inline">
              <div
                v-if="isValidURL(item)"
                class="display-inline word-break"
              >
                <a
                  class="accent-text-link"
                  :href="item"
                  target="_blank"
                >{{
                  item
                }}</a>
              </div>
              <div
                v-else
                class="display-inline word-break"
              >
                {{ item || '–' }}
              </div>
              <span v-if="i !== formData.data.website.length - 1">
                ,
              </span>
            </div>
          </div>
        </div>
        <div 
          v-else
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Domiciled Country
        </div>
        <div
          v-if="formData.data && formData.data.domicileCountry"
          class="entity-view-value"
        >
          <div class="flex align-center">
            <GlImageViewer
              v-if="getCountryByCode(formData.data.domicileCountry)?.icon"
              class="mr-1 flag-icon-small"
              :src="getCountryByCode(formData.data.domicileCountry)?.icon"
            />
            {{ getCountryByCode(formData.data.domicileCountry)?.name }}
          </div>
        </div>
        <div 
          v-else
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Status
        </div>
        <div
          v-if="formData.data && formData.data.status"
          class="entity-view-value bold-600"
        >
          {{ formData.data.status }}
        </div>
        <div 
          v-else
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Type
        </div>
        <div
          v-if="
            formData &&
              formData.type &&
              formData.type
          "
          class="entity-view-value"
        >
          <GlTag
            class="mr-1 mb-1"
            :score="formData.type.score"
            :tag="formData.type.name"
          />
        </div>
        <div 
          v-else
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block max-w-400 overflow-auto">
        <div class="label mb-2 entity-view__small-header">
          Associated tags
        </div>
        <div
          v-if="
            formData &&
              formData.tags &&
              formData.tags.length
          "
          class="entity-view-value"
        >
          <GlTag
            v-for="(tag, index) in formData.tags"
            :key="index"
            class="mr-1 mb-1"
            :score="tag.score"
            :tag="tag.name"
          />
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Description
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.description
          "
          style="white-space: pre-wrap"
        >
          <GlEditor
            class="view-entity-quill-editor"
            disabled
            :value="formData.data.description"
          />
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Screenshots
        </div>
        <VueSkeletonLoader
          v-if="loadingScreenshots"
          animation="wave"
          :color="'#bec3d8'"
          :height="120"
          :radius="'2'"
          type="rect"
        />
        <div v-else>
          <div
            v-if="
              formData.data &&
                formData.data.screenshots &&
                formData.data.screenshots.length
            "
            class="entity-screensot-wrapper"
          >
            <div
              v-for="(item, i) in formData.data.screenshots"
              :key="i"
              class="entity-screensot"
              @click="
                $emit('showViewImageModal')
                $emit('setImageModalData', formData.data.screenshots, i)
              "
            >
              <div
                v-if="item.image && item.image.includes('data:application/pdf;')"
              >
                <embed
                  class="pt-4"
                  :src="item.image"
                  type="application/pdf"
                >
                <div class="entity-screensot-full-view-icon link-text">
                  Full view
                </div>
              </div>
              <GlImageViewer
                v-else
                :src="item.image"
              />
            </div>
          </div>
          <div v-else>
            –
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 pa-4">
      <div class="fs-16 bold-600 mb-4 entity-view__big-header">
        Entity Details
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          KYC
        </div>
        <div
          v-if="
            formData.data && formData.data.KYCStatus
          "
          class="entity-view-value entity-view-value--17 bold-600"
        >
          {{ formData.data.KYCStatus }}
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Provided Services
        </div>
        <div
          v-if="tempProvServicesFilter.length"
          class="entity-view-value entity-view-value--flag flex flex-wrap"
        >
          <div
            v-for="(item, index) in tempProvServicesFilter"
            v-show="index <= showItemsLenght || showFullRegions"
            :key="item.id"
            class="flex align-center mr-2"
          >
            <div>
              {{ item.name }}
            </div>
            <span
              v-if="
                index !== tempProvServicesFilter.length - 1
              "
            >,</span>
          </div>
          <div
            v-if="
              tempProvServicesFilter.length - 1 >
                showItemsLenght && !showFullRegions
            "
            class="accent-text-link pointer"
            @click="showFullRegions = true"
          >
            Show more...
          </div>
          <div
            v-if="
              tempProvServicesFilter.length - 1 >
                showItemsLenght && showFullRegions
            "
            class="accent-text-link pointer"
            @click="showFullRegions = false"
          >
            Show less
          </div>
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Primary operational regions
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.primaryOperationalRegions &&
              formData.data.primaryOperationalRegions.length
          "
          class="entity-view-value entity-view-value--flag flex flex-wrap"
        >
          <div
            v-for="(item, ind) in formData.data.primaryOperationalRegions"
            v-show="ind <= showItemsLenght || showFullRegions"
            :key="item"
            class="flex align-center mr-2"
          >
            <GlImageViewer
              v-if="getCountryByCode(item)?.icon || ''"
              class="mr-1 flag-icon-small"
              :src="getCountryByCode(item)?.icon || ''"
            />
            <div>
              {{ getCountryByCode(item)?.name || item }}
            </div>
            <span
              v-if="
                ind !== formData.data.primaryOperationalRegions.length - 1
              "
            >,</span>
          </div>
          <div
            v-if="
              formData.data.primaryOperationalRegions.length - 1 >
                showItemsLenght && !showFullRegions
            "
            class="accent-text-link pointer"
            @click="showFullRegions = true"
          >
            Show more...
          </div>
          <div
            v-if="
              formData.data.primaryOperationalRegions.length - 1 >
                showItemsLenght && showFullRegions
            "
            class="accent-text-link pointer"
            @click="showFullRegions = false"
          >
            Show less
          </div>
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Restricted Jurisdictions
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.restrictedJurisdictions &&
              formData.data.restrictedJurisdictions.length
          "
          class="entity-view-value entity-view-value--flag flex flex-wrap"
        >
          <div
            v-for="(item, ind) in formData.data.restrictedJurisdictions"
            v-show="ind <= showItemsLenght || showFullJurisdictions"
            :key="item"
            class="flex align-center mr-2"
          >
            <GlImageViewer
              v-if="getCountryByCode(item)?.icon || ''"
              class="mr-1 flag-icon-small"
              :src="getCountryByCode(item)?.icon || ''"
            />
            <div>
              {{ getCountryByCode(item)?.name || item }}
            </div>
            <span
              v-if="
                ind !== formData.data.restrictedJurisdictions.length - 1
              "
            >,</span>
          </div>
          <div
            v-if="
              formData.data.restrictedJurisdictions.length - 1 >
                showItemsLenght && !showFullJurisdictions
            "
            class="accent-text-link pointer"
            @click="showFullJurisdictions = true"
          >
            Show more...
          </div>
          <div
            v-if="
              formData.data.restrictedJurisdictions.length - 1 >
                showItemsLenght && showFullJurisdictions
            "
            class="accent-text-link pointer"
            @click="showFullJurisdictions = false"
          >
            Show less
          </div>
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Fiat currency trading
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.isFiatCurrencyTrading
          "
          class="entity-view-value"
        >
          {{ formData.data.isFiatCurrencyTrading ? 'Yes' : 'No' }}
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>
      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Office addresses
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.officeAddress
          "
          class="entity-view-value"
        >
          {{ formData.data.officeAddress }}
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Languages
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.languages &&
              formData.data.languages.length
          "
        >
          <div
            v-if="formData.data.languages.length"
            class="entity-view-value flex flex-wrap"
          >
            <div
              v-for="(item, ind) in formData.data.languages"
              v-show="ind <= showItemsLenght || showFullLanguage"
              :key="item"
              class="flex align-baseline mr-2"
            >
              {{ item }}
              <span
                v-if="ind !== formData.data.languages.length - 1"
              >,</span>
            </div>
            <div
              v-if="
                formData.data.languages.length - 1 > showItemsLenght &&
                  !showFullLanguage
              "
              class="accent-text-link pointer"
              @click="showFullLanguage = true"
            >
              Show more...
            </div>
            <div
              v-if="
                formData.data.languages.length - 1 > showItemsLenght &&
                  showFullLanguage
              "
              class="accent-text-link pointer"
              @click="showFullLanguage = false"
            >
              Show less
            </div>
          </div>
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Privacy coins supported flag
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.isPrivacyCoinsSupported
          "
          class="entity-view-value"
        >
          {{ formData.data.isPrivacyCoinsSupported ? 'Yes' : 'No' }}
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Social Network link
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.socialNetworkLinks &&
              formData.data.socialNetworkLinks.length
          "
          class="entity-view-value entity-view-value--social flex flex-wrap"
        >
          <div
            v-for="(item, i) in formData.data.socialNetworkLinks"
            :key="i"
            class="flex align-baseline"
          >
            <div
              v-if="item && isEmail(item)"
              class="pointer"
              @click="goMailto(item)"
            >
              <GlImageViewer
                v-if="getValueByKey(socialIconsList, 'Mail', 'key')"
                class="icon-small"
                :src="getValueByKey(socialIconsList, 'Mail', 'key')"
              />
            </div>
            <a
              v-else-if="item && isValidURL(item)"
              :href="item"
              target="_blank"
            >
              <GlImageViewer
                v-if="getValueByKey(socialIconsList, getSocialName(item), 'key')"
                class="icon-small"
                :src="getValueByKey(socialIconsList, getSocialName(item), 'key')"
              />
              <gl-icon
                v-else
                class="mt-1"
                :height="24"
                link
                name="open"
                :width="26"
              /></a>
            <div v-else>
              {{ item || '–' }}
            </div>
          </div>
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import GlImageViewer from '@/components/gl-image-viewer'
import GlTag from '@/components/gl-tag'
import GlIcon from '@/components/gl-icon'
import VueSkeletonLoader from 'skeleton-loader-vue'
import GlEditor from '@/components/gl-editor'
// Utils
import { capitalizeFirstLetter } from '@/utils/text-formatter'
import { isValidURL, isEmail } from '@/utils/text-formatter'
import { getValueByKey } from '@/utils/helpers'
import { goMailto } from '@/utils/go-to-route'
//models
import { socialIconsList } from '@/pages/entity/models/options-list'
import { allCountriesListV2 } from '@/pages/entity/models/countries-list'
import { mapGetters } from 'vuex'

export default {
  name: 'EntityBanksTable',
  components: {
    VueSkeletonLoader,
    GlImageViewer,
    GlTag,
    GlIcon,
    GlEditor
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    loadingScreenshots: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showItemsLenght: 9,
      showFullLanguage: false,
      showFullJurisdictions: false,
      showFullRegions: false,
    }
  },
  computed: {
    ...mapGetters('entity', ['getEntityProvidedServiceList']),
    socialIconsList() {
      return socialIconsList
    },
    tempProvServicesFilter() {
      const providedIds = this.formData?.data?.providedServices || []
      return this.getEntityProvidedServiceList?.items?.filter(service => providedIds.includes(service.id)) || []
    }
  },
  watch: {
    $route: {
      handler() {
        this.showFullLanguage = false
        this.showFullJurisdictions = false
        this.showFullRegions = false
      },
    },
  },
  methods: {
    capitalizeFirstLetter,
    isValidURL,
    isEmail,
    getValueByKey,
    goMailto,
    getSocialName(link) {
      const lowerLink = link.toLowerCase()

      if (lowerLink.includes('instagram.com')) return 'Instagram'
      if (lowerLink.includes('twitter.com') || lowerLink.includes('x.com')) return 'X'
      if (lowerLink.includes('discord.gg')) return 'Discord'
      if (lowerLink.includes('facebook.com')) return 'Facebook'
      if (lowerLink.includes('github.com')) return 'Github'
      if (lowerLink.includes('linkedin.com')) return 'Linkedin'
      if (lowerLink.includes('medium.com')) return 'Medium'
      if (lowerLink.includes('reddit.com')) return 'Reddit'
      if (lowerLink.includes('t.me') || lowerLink.includes('telegram.me')) return 'Telegram'
      if (lowerLink.includes('teletype.in')) return 'Teletype'
      if (lowerLink.includes('threads.net')) return 'Threads'
      if (lowerLink.includes('tiktok.com')) return 'TikTok'
      if (lowerLink.includes('vk.com')) return 'VK'
      if (lowerLink.includes('weibo.com')) return 'Weibo'
      if (lowerLink.includes('youtube.com') || lowerLink.includes('youtu.be')) return 'YouTube'

      return ''
    },
    getCountryByCode(code) {
      return allCountriesListV2.find(country => country.code === code)
    }
  },
}
</script>

<style>
.entity-view-overview-wrapper > div {
  border-right: 1px solid var(--cotton-grey-f-5);
  height: max-content;
}
.entity-view-overview-wrapper > div:first-child {
  min-width: calc(50% + 4px);
}
.entity-view-overview-wrapper > div:first-child .entity-view-value {
  line-height: 17px;
}
.entity-view-overview-wrapper > div:first-child + div {
  min-width: calc(50% - 4px);
}
.view-entity-quill-editor #quill-container {
  height: unset;
}

.view-entity-quill-editor .ql-editor {
  padding: 0;
  line-height: 21px;
}

.view-entity-quill-editor .ql-toolbar.ql-snow {
  display: none;
}

.view-entity-quill-editor .ql-snow.ql-toolbar, .view-entity-quill-editor .ql-container.ql-snow {
  border: none;
}

.view-entity-quill-editor .ql-container {
  font-family: unset;
  font-size: 14px;
}

.entity-view-value {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: var(--space-cadet);
}
.entity-view-value--17 {
  line-height: 17px;
}
.entity-view-value--flag img {
  width: 14px;
}
.entity-view-value--social {
  gap: 16px;
}
.entity-view-value--social a {
  line-height: 1;
}
.entity-view-value--social img {
  width: 24px;
  height: 24px;
  max-height: 24px;
}
.entity-view__big-header {
  line-height: 19px;
}
.entity-view__small-block {
  line-height: 14px;
}
.entity-view__small-header {
  margin-bottom: 9px !important;
  line-height: 14px !important;
}
</style>
